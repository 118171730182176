<script>
  //LAYOUT
  import Header from "./components/layout/Header.svelte";
  import Footer from "./components/layout/Footer.svelte";

  //SECTIONS
  import Hero from "./sections/Hero.svelte";
  import About from "./sections/About.svelte";
  import Clients from "./sections/Clients.svelte";
  import Contact from "./sections/Contact.svelte";

  //OTHERS
  import { onMount } from "svelte";

  const options = {
    rootMargin: "0px",
    threshold: 0.5
  };

  let renderItems = {};
  let renderAbout = false;
  let renderClients = false;
  let renderContact = false;
  let observer;

  const lazyLoad = entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio !== 0 && entry.intersectionRatio !== 1)
        if (entry.isIntersecting) {
          renderItems[entry.target.id] = 1;
          renderAbout = renderItems["about"] === 1;
          renderContact = renderItems["contact"] === 1;
          renderClients = renderItems["clients"] === 1;
          observer.unobserve(entry.target);
        }
    });
  };

  onMount(() => {
    observer = new IntersectionObserver(lazyLoad, options);
    const sections = document.querySelectorAll(".lazy-load");
    sections.forEach(section => observer.observe(section));
  });
</script>

<Header />
<Hero />
<div id="scrollArea">
  <About render={renderAbout} />
  <Clients render={renderClients} />
  <Contact render={renderContact} />
  <Footer />
</div>
