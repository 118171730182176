<script>
  export let title;
  export let image;
  export let description;
</script>

<style>
  .card {
    min-height: 300px;
  }

  .title {
    font-size: 38px;
    line-height: 38px;
    text-align: center;
    letter-spacing: -1px;
    color: #282828;
    margin-bottom: 17px;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #808080;
    letter-spacing: 0;
  }

  img {
    width: 130px;
    height: 130px;
    margin-bottom: 43px;
  }
</style>

<div class="card flex flex-column items-center justify-start">
  <img src={image} alt="design-icon" />
  <h3 class="title">{title}</h3>
  <p class="font-light">{description}</p>
</div>
