<script>
  import Typed from "typed.js";
  import { onMount } from "svelte";
  import { draw, fade } from "svelte/transition";
  import { quintIn } from "svelte/easing";

  onMount(() => {
    let typed = new Typed("#typed", {
      stringsElement: "#typed-strings",
      typeSpeed: 40,
      backSpeed: 100,
      showCursor: false,
      backDelay: 2000
    });
    let into = new Typed("#into", {
      stringsElement: "#into-strings",
      typeSpeed: 40,
      backSpeed: 100,
      showCursor: false,
      startDelay: 40,
      backDelay: 1700
    });

    setTimeout(() => (svgIndex = 0), 500);
  });

  export let svgIndex = null;
  export let fillOpacity = false;
  let show = [false, false, false, false];

  export const fill = () => {
    fillOpacity = true;
    setTimeout(() => {
      if (svgIndex !== null) {
        if (svgIndex < 3) {
          svgIndex++;
          fillOpacity = false;
        } else {
          svgIndex = 3;
        }
      }
    }, 2700);
  };

  export const unFill = () => {
    fillOpacity = false;
  };

  const showNextSvg = () => {
    show[svgIndex] = true;
  };
</script>

<style>
  .hero {
    height: 1px; /*hack*/
    min-height: 100vh;
  }
  .slider-text {
    z-index: 1;
  }
  .slider-svg {
    bottom: 0;
    right: 0;
  }
  path {
    fill-opacity: 0;
    stroke-width: 3;
    stroke-opacity: 0.2;
    stroke: #ffffff;
    transition: fill-opacity 0.2s linear, stroke-width 0.2s linear;
  }
  path.filled {
    fill-opacity: 1;
    stroke-width: 0;
  }

  svg {
    width: 1085px;
    height: 690px;
  }

  #typed,
  #into {
    line-height: 76px;
    letter-spacing: -2px;
  }
</style>

<div class="hero bg-orange relative">
  <div
    class="container slider-text h-full flex flex-column justify-center
    items-left relative">
    <h5 class="text-white font-medium">We transform:</h5>
    <div id="typed-strings">
      <h1>
        Any
        <span class="text-light-orange"></span>
      </h1>
      <h1>
        Any
        <span class="text-light-orange">idea</span>
      </h1>
      <h1>
        Any
        <span class="text-light-orange">web-design</span>
      </h1>
      <h1>
        Any
        <span class="text-light-orange">user-experience</span>
      </h1>
      <h1>
        Any
        <span class="text-light-orange">html/css markup</span>
      </h1>
    </div>
    <div id="into-strings">
      <h1>
        into a
        <span class="text-gray"></span>
      </h1>
      <h1>
        into a
        <span class="text-gray">web-design</span>
      </h1>
      <h1>
        into a
        <span class="text-gray">full user experience</span>
      </h1>
      <h1>
        into a
        <span class="text-gray">html/css template</span>
      </h1>
      <h1>
        into a
        <span class="text-gray">real application.</span>
      </h1>
    </div>
    <h1 id="typed" class="text-white">Any</h1>
    <h1 class="observe-me text-white" id="into">into</h1>

  </div>
  <div class="slider-svg absolute">
    {#if svgIndex === 0}
      <svg
        out:fade={{ duration: 200 }}
        on:outroend={showNextSvg}
        viewBox="0 0 1085 690"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          on:introstart={unFill}
          on:introend={fill}
          in:draw={{ duration: 1500, easing: quintIn }}
          class:filled={fillOpacity}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M215.372 90.4167H1030.75C1060.71 90.4167 1085 114.705 1085
          144.667V687.167C1084.91 710.074 1070.43 730.454 1048.83
          738.089V759.5C1048.83 789.461 1024.54 813.75 994.583
          813.75H108.5C48.6019 813.69 0.0598084 765.148 0 705.25V108.5C0.325287
          52.3386 43.4547 5.70789 99.4201 1.00886C155.386 -3.69018 205.687
          35.0958 215.372 90.4167ZM180.833 108.5C180.833 68.5514 148.449 36.1667
          108.5 36.1667C68.5514 36.1667 36.1667 68.5514 36.1667
          108.5V624.598C77.2418 587.469 139.758 587.469 180.833
          624.598V108.5ZM994.583 777.583H108.5C68.5514 777.583 36.1667 745.199
          36.1667 705.25C36.1667 665.301 68.5514 632.917 108.5 632.917C148.449
          632.917 180.833 665.301 180.833 705.25C180.833 725.224 197.026 741.417
          217 741.417H1012.67V759.5C1012.67 769.487 1004.57 777.583 994.583
          777.583ZM512.861 334.686L488.25 290.4L463.639 334.686C477.882 346.603
          498.618 346.603 512.861 334.686ZM446.098 366.35C471.321 384.336
          505.179 384.336 530.402 366.35L554.146 409.099C512.689 429.95 463.811
          429.95 422.354 409.099L446.098 366.35ZM560.583 446.206C514.379 465.935
          462.121 465.935 415.917 446.206V705.25H560.583V446.206ZM849.917
          271.25H813.75C803.763 271.25 795.667 279.346 795.667 289.333C795.667
          299.32 803.763 307.417 813.75 307.417H849.917V343.583H777.583C767.596
          343.583 759.5 351.68 759.5 361.667C759.5 371.654 767.596 379.75
          777.583 379.75H849.917V415.917H813.75C803.763 415.917 795.667 424.013
          795.667 434C795.667 443.987 803.763 452.083 813.75
          452.083H849.917V488.25H777.583C767.596 488.25 759.5 496.346 759.5
          506.333C759.5 516.32 767.596 524.417 777.583
          524.417H849.917V560.583H813.75C803.763 560.583 795.667 568.68 795.667
          578.667C795.667 588.654 803.763 596.75 813.75
          596.75H849.917V632.917H777.583C767.596 632.917 759.5 641.013 759.5
          651C759.5 660.987 767.596 669.083 777.583
          669.083H849.917V705.25H705.25V235.083H849.917V271.25ZM1030.75
          705.25C1040.74 705.25 1048.83 697.154 1048.83 687.167V144.667C1048.83
          134.68 1040.74 126.583 1030.75
          126.583H217V705.25H379.75V415.917C379.75 415.712 379.798 415.556
          379.851 415.383C379.906 415.204 379.967 415.008 379.985
          414.723C380.001 414.47 380.051 414.221 380.1 413.972C380.16 413.67
          380.22 413.367 380.22 413.06C380.496 411.235 381.058 409.465 381.884
          407.815C381.935 407.714 381.958 407.604 381.981 407.494C382.007
          407.369 382.034 407.244 382.101 407.128L472.518 244.378C472.645
          244.152 472.821 243.98 472.995 243.808C473.142 243.664 473.288 243.521
          473.404 243.347C474.828 241.393 476.548 239.672 478.503
          238.248C478.691 238.128 478.842 237.977 478.994 237.825C479.163
          237.655 479.332 237.486 479.552 237.362C480.141 237.123 480.745
          236.923 481.36 236.765C482.481 236.27 483.651 235.894 484.85
          235.644C488.309 235.023 491.874 235.406 495.122 236.747C495.766
          236.914 496.4 237.119 497.02 237.362C497.262 237.492 497.441 237.673
          497.622 237.855C497.766 238 497.911 238.145 498.087 238.266C500.036
          239.684 501.75 241.399 503.169 243.347C503.284 243.521 503.43 243.664
          503.577 243.808C503.752 243.98 503.927 244.152 504.055 244.378L594.471
          407.128C594.539 407.244 594.565 407.369 594.592 407.494C594.615
          407.604 594.638 407.714 594.688 407.815C595.515 409.465 596.076
          411.235 596.352 413.06C596.37 413.168 596.388 413.28 596.408
          413.395C596.487 413.875 596.573 414.387 596.587 414.723C596.596
          414.918 596.632 415.101 596.668 415.284C596.709 415.49 596.75 415.696
          596.75 415.917V705.25H669.083V235.083C669.083 215.109 685.276 198.917
          705.25 198.917H849.917C869.891 198.917 886.083 215.109 886.083
          235.083V705.25H1030.75Z"
          fill="#FF8425" />
      </svg>
    {/if}

    {#if svgIndex === 1 && show[1]}
      <svg
        out:fade={{ duration: 200 }}
        on:outroend={showNextSvg}
        viewBox="0 0 1085 810"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          on:introstart={unFill}
          on:introend={fill}
          in:draw={{ duration: 1700, easing: quintIn }}
          class:filled={fillOpacity}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M54.25 0H1030.75C1060.71 0 1085 24.2886 1085 54.25V813.75C1085
          843.711 1060.71 868 1030.75 868H687.167V940.333C737.078 940.393
          777.523 980.839 777.583 1030.75V1066.92C777.583 1076.9 769.487 1085
          759.5 1085H325.5C315.513 1085 307.417 1076.9 307.417
          1066.92V1030.75C307.476 980.839 347.922 940.393 397.833
          940.333V868H54.25C24.2886 868 0 843.711 0 813.75V54.25C0 24.2886
          24.2886 0 54.25 0ZM55.6535 35.8819C45.6776 35.8819 37.5905 43.9781
          37.5905 53.9652V686.882H1049.12V53.9652C1049.12 43.9781 1041.03
          35.8819 1031.06 35.8819H55.6535ZM741.559
          1030.75V1049.12H343.441V1030.75C343.441 1000.32 367.747 975.646 397.73
          975.646H687.27C717.253 975.646 741.559 1000.32 741.559 1030.75ZM434
          941.472H651V868H434V941.472ZM1049.12 813.892C1049.12 823.958 1041.03
          832.118 1031.06 832.118H55.6535C45.6776 832.118 37.5905 823.958
          37.5905 813.892V722.764H1049.12V813.892ZM579.806
          787.693H506.903C496.837 787.693 488.677 779.278 488.677
          768.898C488.677 758.517 496.837 750.102 506.903
          750.102H579.806C589.872 750.102 598.031 758.517 598.031
          768.898C598.031 779.278 589.872 787.693 579.806 787.693Z"
          fill="#FF8425" />
      </svg>
    {/if}

    {#if svgIndex === 2 && show[2]}
      <svg
        out:fade={{ duration: 200 }}
        on:outroend={showNextSvg}
        viewBox="0 0 1085 690"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          on:introstart={unFill}
          on:introend={fill}
          in:draw={{ duration: 1900, easing: quintIn }}
          class:filled={fillOpacity}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1083.37 287.846C1082.41 284.877 1080.82 282.151 1078.71
          279.856C1078.52 279.637 1078.35 279.407 1078.19 279.177C1077.94
          278.837 1077.69 278.498 1077.38 278.204C1077.36 278.188 1077.35
          278.173 1077.34 278.156C1077.33 278.147 1077.32 278.139 1077.31
          278.13L1077.3 278.109C1077.27 278.067 1077.24 278.022 1077.21
          277.978C1077.18 277.937 1077.15 277.896 1077.12 277.858C1077.11
          277.836 1077.09 277.816 1077.07 277.796C1077.06 277.784 1077.05
          277.772 1077.04 277.76C1077.03 277.753 1077.02 277.746 1077.01
          277.739L1077.01 277.735L781.101 6.48534C779.139 4.72763 776.908
          3.29569 774.493 2.24398C774.144 2.08433 773.779 1.98448 773.412
          1.8841L773.411 1.88404C773.155 1.81388 772.897 1.74345 772.643
          1.65216C770.428 0.866246 768.111 0.401236 765.763 0.27125C765.54
          0.259513 765.334 0.197499 765.129 0.135728C764.903 0.0677171 764.677 0
          764.432 0H98.6364C44.161 0 0 44.161 0 98.6364V1380.91C0 1435.38 44.161
          1479.55 98.6364 1479.55H986.364C1040.84 1479.55 1085 1435.38 1085
          1380.91V295.909C1085 295.598 1084.92 295.321 1084.84 295.046L1084.84
          295.046C1084.76 294.797 1084.69 294.551 1084.68 294.282C1084.53
          292.088 1084.09 289.924 1083.37 287.846ZM789.091 80.7092L996.967
          271.25H789.091V80.7092ZM739.773 49.3182H98.6364C71.3987 49.3182
          49.3182 71.3987 49.3182
          98.6364V937.045H1035.68V320.568H764.432C750.813 320.568 739.773
          309.528 739.773 295.909V49.3182ZM986.364 1430.23H98.6364C71.3987
          1430.23 49.3182 1408.15 49.3182
          1380.91V986.364H1035.68V1380.91C1035.68 1408.15 1013.6 1430.23 986.364
          1430.23ZM961.705 640.727H147.954C134.336 640.727 123.295 629.687
          123.295 616.068C123.295 602.449 134.336 591.409 147.954
          591.409H961.705C975.323 591.409 986.364 602.449 986.364
          616.068C986.364 629.687 975.323 640.727 961.705 640.727ZM147.954
          542.091H369.886C383.505 542.091 394.545 531.051 394.545
          517.432C394.545 503.813 383.505 492.773 369.886
          492.773H147.954C134.336 492.773 123.295 503.813 123.295
          517.432C123.295 531.051 134.336 542.091 147.954 542.091ZM493.182
          492.773H961.705C975.323 492.773 986.364 503.813 986.364
          517.432C986.364 531.051 975.323 542.091 961.705
          542.091H493.182C479.563 542.091 468.523 531.051 468.523
          517.432C468.523 503.813 479.563 492.773 493.182 492.773ZM147.954
          172.614H221.932C235.551 172.614 246.591 161.573 246.591
          147.955C246.591 134.336 235.551 123.295 221.932
          123.295H147.954C134.336 123.295 123.295 134.336 123.295
          147.955C123.295 161.573 134.336 172.614 147.954 172.614ZM468.523
          172.614H320.568C306.949 172.614 295.909 161.573 295.909
          147.955C295.909 134.336 306.949 123.295 320.568
          123.295H468.523C482.142 123.295 493.182 134.336 493.182
          147.955C493.182 161.573 482.142 172.614 468.523 172.614Z"
          fill="#FF8425" />
      </svg>
    {/if}
    {#if svgIndex === 3 && show[3]}
      <svg
        width="1085"
        height="690"
        viewBox="0 0 1085 690"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          on:introstart={unFill}
          on:introend={fill}
          in:draw={{ duration: 1700, easing: quintIn }}
          class:filled={fillOpacity}
          fill-rule="evenodd"
          stroke="#ffffff"
          stroke-width="3"
          clip-rule="evenodd"
          d="M1084.98 691.853C1084.97 683.296 1080.15 675.471 1072.52
          671.629L904.419 587.041L1072.68 500.709C1080.24 496.838 1085 489.049
          1085 480.541C1085 472.034 1080.24 464.245 1072.68 460.374L917.71
          380.904L1072.48 303.45C1080.14 299.614 1084.98 291.771 1084.98
          283.192C1084.98 274.612 1080.14 266.769 1072.48 262.934L552.592
          2.48863C546.231 -0.69608 538.746 -0.69608 532.385 2.48863L12.4999
          262.934C4.84027 266.769 0.00154774 274.612 0.00154774
          283.192C0.00154774 291.771 4.84027 299.614 12.4999 303.45L167.267
          380.927L56.5092 437.727C13.0639 460.083 3.02015 465.252 0.698214
          472.797C1.18009e-05 475.065 1.1821e-05 477.549 1.1821e-05
          480.779C-0.00864743 489.285 4.74065 497.079 12.2964 500.958L180.287
          587.018L12.4546 671.629C4.81986 675.474 0.00144015 683.303 0.00144015
          691.864C0.00144015 700.426 4.81986 708.255 12.4546 712.1L532.339
          973.677C538.737 976.908 546.285 976.908 552.683 973.677L1072.57
          712.1C1080.19 708.241 1084.99 700.409 1084.98 691.853ZM660.499
          107.608L1011.1 283.15L541.964 518.065L72.8332 283.15L541.964
          48.258L624.441 89.5537L378.123 212.47C369.078 216.987 365.414 227.967
          369.939 236.996C374.464 246.024 385.464 249.681 394.509
          245.165L651.836 116.727C655.727 114.798 658.789 111.559 660.499
          107.608ZM217.382 405.697L185.824 421.915L168.02 431.061L167.999
          431.072C130.385 450.396 96.265 467.924 71.7621 480.54L542.579
          722.469L1013.24 480.517L867.776 405.81L552.691 563.992C546.325 567.186
          538.833 567.186 532.467 563.992L316.355 455.372L234.621
          496.394C231.491 497.978 228.035 498.802 224.531 498.802C214.026
          498.822 204.889 491.579 202.472 481.314C200.055 471.049 204.994
          460.464 214.396 455.759L265.749 429.978L217.382 405.697ZM542.01
          927.942L72.8332 692.352L229.926 613.311L291.114 644.611L215.696
          682.258C204.642 687.889 200.199 701.379 205.745 712.475C211.29 723.57
          224.748 728.117 235.888 722.659L340.986 670.266L531.642
          767.803C538.109 771.116 545.775 771.116 552.242 767.803L853.778
          613.378L1011.1 692.42L542.01 927.942Z"
          fill="#FF8425" />
      </svg>
    {/if}

  </div>
</div>
