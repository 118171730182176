<script>
  import { fly } from "svelte/transition";
  import Testimonial from "../components/clients/Testimonial.svelte";

  let clients = [
    {
      svg: "img/cherca-logo.svg",
      link: "https://www.chercaonline.com.ar/",
    },
    {
      svg: "img/aulica-logo.png",
      link: "https://www.aulica.com.ar/",
    },
    {
      svg: "img/ataraxy-logo.png",
      link: "https://www.ataraxydigital.com/",
    },
    {
      svg: "img/venga-logo.png",
      link: "https://www.vengaglobal.com/",
    },
    {
      svg: "img/audela-logo.png",
      link: "https://au-dela.ca/",
    },
    {
      svg: "img/ff-logo.svg",
      link: "https://edyahn.com/",
    },
    {
      svg: "img/xoor-logo.svg",
      link: "https://xoor.io/",
    },
    {
      svg: "img/soluciones-logo.png",
      link: "http://www.spostales.com.ar/",
    },
    {
      svg: "img/mediterranea-logo.png",
      link: "http://www.mediterraneaturismo.tur.ar/",
    },
    {
      svg: "img/anden-logo.png",
      link: "http://www.andensolutions.com/",
    },
    {
      svg: "img/jar-logo.png",
      link: "",
    },
    {
      svg: "img/entrep-logo.png",
      link: "https://www.entrep.net/",
    },
    {
      svg: "img/docin-logo.png",
      link: "https://docin.com.ar/#/",
    },
    {
      svg: "img/desarrollo-logo.png",
      link: "",
    },
    {
      svg: "img/hespinosa-logo.png",
      link: "https://hespinosa.com.ar/",
    },
    {
      svg: "img/lagom-logo.png",
      link: "https://lagom.us",
    },
    {
      svg: "img/softminers-logo.png",
      link: "https://softminers.com/",
    },
    {
      svg: "img/norte-logo.png",
      link: "",
    },
    {
      svg: "img/saywhat-logo.png",
      link: "",
    },
    {
      svg: "img/geek-logo.png",
      link: "",
    },
  ];

  let testimonials = [
    {
      id: 5,
      description:
        "The craCSS team is awesome, we’ve done several projects with them and always had great results. Communication is fluid, so they understand quickly what needs to be done and deliver on time all projects. We’ll keep working with them for a long time!",
      client: "Mauricio Payetta",
      position: "Founder and CEO Xoor",
    },
    {
      id: 4,
      description:
        "Venga has been working with craCSS on our new corporate website. The craCSS team have been very responsive and helpful as they build out our design (which was a little complex at times). They have been quick to answer questions and present solutions and I anticipate a successful launch upon project completion.",
      client: "Stephanie Harris",
      position: "Marketing Manager Venga Global",
    },
    {
      id: 2,
      description:
        "I live in the United States and was referred by a long time friend to craCSS to develop my personal website, which showcased my experience as a UX design leader and a portfolio of my case studies. I designed my website, but I needed them to bring my ideas come to life and provide a CMS for me to add/update the content easily. They gave me a good price, and we got started. The team translated my design perfectly into a fully functioning website for both desktop and mobile devices. I'm extremely picky, and the craCSS team was able to meet my needs with exceptional quality. I would highly recommend them.",
      client: "Ed Yahn",
      position: "Director of User Experience",
    },
    {
      id: 1,
      description:
        "The craCSS team is efficient, professional. They quickly understood our company’s requirements and provided results fast. We would definitely hire them again for future web UI/UX needs!",
      client: "Lorraine Atik",
      position: "HR Manager Au-Dela",
    },
  ];

  let testimonialIndex = 0;

  const incrementTestimonial = () => {
    if (testimonialIndex < testimonials.length - 1) {
      testimonialIndex++;
    } else {
      testimonialIndex = 0;
    }

    setTimeout(() => setHeight(), 500);
  };

  const decrementTestimonial = () => {
    if (testimonialIndex > 0) {
      testimonialIndex--;
    } else {
      testimonialIndex = testimonials.length - 1;
    }

    setTimeout(() => setHeight(), 500);
  };

  export let render = false;

  $: childHeight = 0;

  const setHeight = () => {
    let activeElement = document.querySelector(".active");
    childHeight = activeElement.clientHeight;
  };
</script>

<section id="clients" class="lazy-load h-full bg-light-gray">
  {#if render}
    <div
      in:fly={{ duration: 3000, y: 100 }}
      on:introstart={setHeight}
      class="container relative flex flex-column items-center"
    >
      <h1 class="text-orange title">Client Testimonial</h1>
      <button on:click={decrementTestimonial} class="left-arrow" />
      <div
        id="testimonials"
        style="height: {childHeight}px"
        class="testimonials"
      >
        {#each testimonials as testimonial, index (testimonial.id)}
          <div class="testimonial" class:active={testimonialIndex === index}>
            <Testimonial {...testimonial} />
          </div>
        {/each}
      </div>
      <button on:click={incrementTestimonial} class="right-arrow" />
      <div class="flex flex-wrap items-center justify-center client-logos">
        {#each clients as client, i (i)}
          <div
            class="logo-container w-1/2 md:w-1/4 flex items-center
            justify-center"
          >
            <a
              href={client.link}
              style="background-image: url('{client.svg}')"
              class:events-inactive={!client.link}
              target="_blank"
            >
              &nbsp;
            </a>
          </div>
        {/each}
      </div>
    </div>
  {/if}
</section>

<style>
  .testimonial {
    width: 100%;
    position: absolute;
    display: block;
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
  }

  .testimonial.active {
    opacity: 1;
  }

  .testimonials {
    width: 100%;
    position: relative;
    pointer-events: none;
    transition: height 0.3s ease-in;
    margin-bottom: 16px;
  }

  button {
    width: 30px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: 0;
    position: absolute;
    top: 300px;
  }

  .left-arrow {
    background-image: url("img/left-arrow.svg");
    left: 0;
  }

  .right-arrow {
    background-image: url("img/right-arrow.svg");
    right: 0;
  }

  .logo-container {
    margin-top: 30px;
  }

  a {
    width: 130px;
    height: 90px;
    display: block;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    text-decoration: none;
  }

  section {
    min-height: 700px;
  }

  .client-logos {
    margin-top: 10rem;
  }

  @media (min-width: 768px) {
    button {
      top: 200px;
    }
  }

  @media (min-width: 576px) {
    .client-logos {
      margin-top: 0;
    }

    a {
      width: 160px;
    }
  }

  .title {
    line-height: 76px;
    letter-spacing: -2px;
    margin-bottom: 63px;
  }

  .events-inactive {
    pointer-events: none;
    cursor: none;
  }
</style>
