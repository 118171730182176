<script>
  export let description;
  export let client;
  export let position;
</script>

<style>
  .testimonial {
    position: relative;
    width: 80%;
    margin: 0 auto;
  }

  p {
    font-size: 29px;
    line-height: 37px;
    text-align: center;
    letter-spacing: -1px;
    color: #808080;
    margin-bottom: 54px;
  }

  h5 {
    font-size: 29px;
    line-height: 37px;
    text-align: center;
    letter-spacing: -1px;
  }
</style>

<div class="testimonial">
  <p class="text-center mb-6 font-light">{description}</p>
  <h5 class="text-orange text-center">{client}. <span class="font-light">{position}</span></h5>
</div>
