<script>
  export let logo;
  export let description;
</script>

<style>
  div {
    min-height: 200px;
    min-width: 350px;
  }

  img {
    height: 120px;
    width: 120px;
  }

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 29px;
    line-height: 37px;
    text-align: center;
    letter-spacing: -1px;
  }
</style>

<div class="main flex flex-column items-center justify-evenly">
  <img src={logo} alt="img-logo" />
  <h5 class="text-white text-center">{description}</h5>
</div>
