<script>
  import * as animateScroll from "svelte-scrollto";
  import { onMount } from "svelte";

  const options = {
    rootMargin: "0px",
    threshold: 1
  };

  let observer;
  let modalActive = false;
  let isMobile = false;

  onMount(() => {
    isMobile = window.matchMedia("(max-width: 576px)").matches;
  });

  let headerItems = [
    {
      label: "About",
      scrollto: "#about"
    },
    {
      label: "Clients",
      scrollto: "#clients"
    },
    {
      label: "Contact",
      scrollto: "#contact"
    }
  ];

  let counter = 0;

  function doScroll(e) {
    let easing = counter === 0 ? 'cubicIn' : 'linear';

    animateScroll.scrollTo({
      element: e,
      duration: 1000,
      easing: easing,
      onDone: function(e) {
        counter++
        if(!e.children.length) {
          doScroll(e)
        }
      }
    })
  }

  const closeAndScroll = e => {
    counter = 0;
    modalActive = false;
    doScroll(e);
  };

  const justScroll = e => {
    counter = 0;
    doScroll(e);
  }
</script>

<style>
  .header {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 90;
    padding-top: 38px;
    padding-bottom: 38px;
    transition: background-color 0.5s ease-in;
  }

  .nav-links {
    width: 232px;
    color: #fff;
    cursor: pointer;
  }

  .nav-links li:hover {
    opacity: 0.8;
  }

  img {
    cursor: pointer;
  }

  span {
    cursor: pointer;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .close {
    position: fixed;
    top: 0;
    right: 25px;
    font-size: 58px;
    color: #ffffff;
  }

  .mobile-icon {
    position: fixed;
    top: 0;
    right: 25px;
    height: 50px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.3);
    padding: 10px 12px;
    border-bottom-right-radius: 35%;
    border-bottom-left-radius: 35%;
  }
</style>

{#if !isMobile}
  <div class="header absolute">
    <div class="container flex items-center justify-between h-full">
      <img
        on:click={() => animateScroll.scrollToTop()}
        src="img/cracss.svg"
        alt="craCSS" />
      <ul class="list-unstyled flex nav-links justify-between">
        {#each headerItems as item}
          <li>
            <span
              on:click={() => justScroll(item.scrollto)}>
              {item.label}
            </span>
          </li>
        {/each}
      </ul>
    </div>
  </div>
{/if}

{#if isMobile}
  <img
    class="mobile-icon shadow-1"
    on:click={() => (modalActive = true)}
    src="img/C.png"
    alt="craCSS" />
  <div
    class:flex={modalActive}
    class="modal display-none items-center justify-center">
    <span class="close" on:click={() => (modalActive = false)}>&times;</span>
    <ul
      class="list-unstyled flex flex-column items-center justify-center w-full
      h-full">
      {#each headerItems as item}
        <li class="py-2 w-full flex items-center justify-center">
          <span
            class="flex items-center justify-center font-5xl text-center
            text-white w-full h-full"
            on:click={() => closeAndScroll(item.scrollto)}>
            {item.label}
          </span>
        </li>
      {/each}
    </ul>
  </div>
{/if}
