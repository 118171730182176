<script>
  import { fly } from "svelte/transition";
  import Logo from "../components/contact/Logos.svelte";

  const info = [
    {
      logo: "img/email-icon.svg",
      description: "info@cracss.com"
    },
    {
      logo: "img/phone-icon.svg",
      description: "+54 9 351 8068535"
    }
  ];

  export let render = false;
</script>

<style>
  section {
    min-height: 700px;
  }

  .title {
    line-height: 76px;
    letter-spacing: -2px;
  }

  .logos {
    margin-top: 105px;
  }
</style>

<section id="contact" class="lazy-load bg-gray">
  {#if render}
    <div in:fly={{ duration: 3000, y: 100 }} class="container">
      <h1 class="text-orange md:w-1/2 title">
        Let's talk about your next project
      </h1>
      <div
        class="logos flex flex-column md:flex-row items-center justify-center">
        {#each info as item}
          <Logo logo={item.logo} description={item.description} />
        {/each}
      </div>
    </div>
  {/if}
</section>
