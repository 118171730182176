<script>
  import Card from "../components/about/Card.svelte";
  import { fly } from "svelte/transition";

  export let cards = [
    {
      title: "Design",
      image: "img/design-icon.svg",
      description:
        "Bring your design (PSD, Sketch, any format) and we will create a beautiful, well coded, dynamic and fast User Interface for you. Or ask us to design it for you."
    },
    {
      title: "UX/UI",
      image: "img/ux-icon.svg",
      description:
        "We aim to collect as much information, business goals and user needs as possible to create a really unique experience to your users."
    },
    {
      title: "Frontend",
      image: "img/frontend-icon.svg",
      description:
        "We create beautiful web designs that fits every screen size. We understand this is important for your business. Actually 70% of total web traffic is through mobile devices, and even this site fits any screen."
    },
    {
      title: "Backend",
      image: "img/backend-icon.svg",
      description:
        "Yes. We do that. Even when we are Frontend pourists, we work with Backend technologies also. So You are all covered."
    }
  ];

  export let render = false;
</script>

<style>
  section {
    min-height: 125vh;
  }
  .title-container {
    margin-bottom: 109px;
  }

  .title {
    line-height: 76px;
    letter-spacing: -2px;
    margin-bottom: 33px;
  }

  .subtitle {
    line-height: 37px;
    letter-spacing: -1px;
    color: #808080;
  }
</style>

<section id="about" class="lazy-load">
  {#if render}
    <div
      in:fly={{ duration: 3000, y: 100 }}
      class="container grid has-1-columns md:has-2-columns row-gap-40
      column-gap-20 ">
      <div class="title-container mb-12">
        <h1 class="text-orange title">From your idea to reality</h1>
        <h5 class="subtitle font-light">
          We are a consultancy, web design, coding and frontend full service.
        </h5>
      </div>
      {#each cards as card, index}
        <div class:column-start-1={index === 0}>
          <Card {...card}/>
        </div>
      {/each}

    </div>
  {/if}
</section>
